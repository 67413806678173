import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'react-simple-loading';
import { Helmet } from 'react-helmet-async';

export const PostScreen = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(`API URL: ${apiUrl}?slug=${slug}&_embed`);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}?slug=${slug}&_embed`
        );
        if (!response.ok) {
          throw new Error('Error fetching post');
        }
        const data = await response.json();
        if (data.length > 0) {
          setPost(data[0]);
        } else {
          setError('Post not found');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [slug]);

  if (loading) return <Loading color={'firebrick'} />;
  if (error) return <p>Error: {error}</p>;
  console.log(post);

  // Funciones de extracción separadas
  const getAuthorName = () =>
    post?._embedded?.author?.[0]?.name || 'Unknown author';

  const getFeaturedImage = () =>
    post?._embedded?.['wp:featuredmedia']?.[0]?.source_url ||
    'default-image-url';

  const cleanExcerpt = () =>
    post?.excerpt?.rendered
      ? post.excerpt.rendered.replace(/<[^>]+>/g, '').trim()
      : '';

  return (
    <div>
      {post && (
        <>
          <Helmet>
            <title>{post.title.rendered}</title>
            <meta property="og:title" content={post.title.rendered} />
            <meta property="og:description" content={cleanExcerpt()} />
            <meta property="og:image" content={getFeaturedImage()} />
            <meta property="og:url" content={`${post.link}${slug}`} />
            <meta property="og:type" content="article" />
            <meta name="twitter:card" content="summary_large_image" />
            {/* Opcional: Añade metadatos de Twitter */}
            <meta name="twitter:title" content={post.title.rendered} />
            <meta name="twitter:description" content={cleanExcerpt()} />
            <meta name="twitter:image" content={getFeaturedImage()} />
          </Helmet>
          <p>
            <strong>Por</strong> {getAuthorName()}
          </p>
          <p>Miercoles 04 septiembre de 2024 | 18:02</p>
          <div
            className="bgImage"
            style={{
              marginBottom: '2em',
              backgroundImage: `url(${getFeaturedImage()})`,
              backgroundSize: 'cover',
              width: '100%',
              backgroundPosition: 'center',
              height: '55vh',
            }}
          ></div>
          <h1>{post.title.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
        </>
      )}
    </div>
  );
};
